import React, { useEffect, useState } from 'react'
import styles from './SolarPanelSelection.module.scss'
import { SOLAR_PANEL_AMOUNTS } from 'features/energy-gardens/constants'
import { EnergyGardens } from 'features/energy-gardens/types'
import classNames from 'classnames'
import { Button } from '@boltenergy-be/design-system'
import useWindowSize from 'hooks/useWindowSize'
import MobileDrawer from 'components/drawer/variant/MobileDrawer'
import { useRouter } from 'next/router'
import { SolarPanelSelectionTypes } from 'features/energy-gardens/landingpage/components/solar-panel-selection/types'
import HardcodedSolarPanelDescription from 'features/energy-gardens/common/hardcoded-solarpanel-description/HardcodedSolarPanelDescription'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { updateEnergyGardenInput } from 'store/energy-gardens/slice'
import { useGetEnergyGardenBySlugQuery } from 'store/api/boltApi'
import LoadingSkeleton from 'components/loading-skeleton/LoadingSkeleton'
import OrderButton from 'features/energy-gardens/landingpage/components/order-button/OrderButton'
import { ProgressIndicator } from '@boltenergy-be/design-system'
import { determineEnergyGardenPhaseData } from 'features/energy-gardens/landingpage/utils'

const Body = ({ solarPanels }: SolarPanelSelectionTypes) => {
  // Redux
  const { inputs } = useStoreSelector((state) => state.energyGardens[EnergyGardens.BRUGSE_ZONNETUIN])
  const dispatch = useStoreDispatch()

  // Router
  const { query } = useRouter()
  const { slug } = query

  // Local state
  const [solarPanelAmount, setSolarPanelAmount] = useState<number>(
    inputs?.products ? Object.values(inputs.products)[0] : SOLAR_PANEL_AMOUNTS[EnergyGardens.BRUGSE_ZONNETUIN][1]
  )

  // Constants
  const { pricePerUnit = 0, soldOut = false, available = 0 } = solarPanels || {}
  const totalPrice = solarPanelAmount * pricePerUnit

  return (
    <>
      {/* Panel selection */}
      <div className={styles['panel-selection']}>
        <h4 className={styles['panel-selection-title']}>Kies een aantal panelen</h4>
        <ul className={styles.buttons}>
          {SOLAR_PANEL_AMOUNTS[slug as string].map((amount: number) => (
            <li key={amount}>
              <button
                disabled={soldOut || available < amount}
                onClick={() => {
                  setSolarPanelAmount(amount)
                  dispatch(
                    updateEnergyGardenInput({
                      garden: slug as EnergyGardens,
                      inputs: { products: { [solarPanels._id]: amount } }
                    })
                  )
                }}
                className={classNames(
                  styles['amount-button'],
                  { [styles['selected-amount']]: amount === solarPanelAmount },
                  { [styles.soldout]: soldOut || available < amount }
                )}
              >
                {amount}
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* Panel Price */}
      {!!totalPrice && (
        <div className={styles['panel-price']}>
          <span className={styles.price}>€ {totalPrice}</span>
        </div>
      )}

      {/* Panel Description */}
      <HardcodedSolarPanelDescription quantity={solarPanelAmount} />

      <OrderButton variant="primary" />
    </>
  )
}

const SolarPanelSelection = () => {
  // Local state
  const [showMobileDrawer, setShowMobileDrawer] = useState<boolean>(false)

  // Window size
  const { isSmaller } = useWindowSize(900)

  // Router
  const { query } = useRouter()
  const { slug } = query

  // RTQuery
  const { data, isLoading } = useGetEnergyGardenBySlugQuery(slug as string)

  // Constants
  const isNotMobile = !isSmaller
  const { products = [] } = data || {}
  const solarPanels = products.find((product) => product.name === 'solar panels')
  const { totalQuantity = 0, available = 0 } = solarPanels || {}
  const panelsSold = totalQuantity - available
  const { availableInPhase, percentage } = determineEnergyGardenPhaseData(EnergyGardens.BRUGSE_ZONNETUIN, panelsSold)

  useEffect(() => {
    if (showMobileDrawer && isNotMobile) {
      setShowMobileDrawer(false)
    }
  }, [showMobileDrawer, isNotMobile])

  if (isNotMobile) {
    return (
      <div className={classNames(styles.root, styles.sticky)}>
        {isLoading ? (
          <>
            <LoadingSkeleton className={styles.header}>
              <LoadingSkeleton.Paragraph lines={2} />
              <LoadingSkeleton.Rectangle height={8} />
            </LoadingSkeleton>
            <LoadingSkeleton className={styles.body}>
              <LoadingSkeleton.Rectangle height={200} />
            </LoadingSkeleton>
          </>
        ) : (
          <>
            <div className={styles.header}>
              <h3 className={styles['header-title']}>Nog maar {availableInPhase} zonnepanelen over</h3>
              <ProgressIndicator id="solar-panel-progress" progress={percentage} />
            </div>
            <div className={styles.body}>
              <Body solarPanels={solarPanels} />
            </div>
          </>
        )}
      </div>
    )
  }

  if (showMobileDrawer) {
    return (
      <MobileDrawer
        isOpen={true}
        onRequestClose={() => setShowMobileDrawer(false)}
        extraHeaderElement={
          <div className={classNames(styles.header, styles['no-padding'])}>
            <h3 className={styles['header-title']}>Nog maar {availableInPhase} zonnepanelen over</h3>
            <ProgressIndicator id="solar-panel-progress" progress={percentage} />
          </div>
        }
      >
        <div className={classNames(styles.body, styles['no-padding'])}>
          <Body solarPanels={solarPanels} />
        </div>
      </MobileDrawer>
    )
  }

  return (
    <div className={styles['show-panel']}>
      <Button isFullwidth loading={isLoading} onClick={() => setShowMobileDrawer(true)}>
        Kies je zonnepanelen
      </Button>
    </div>
  )
}

export default SolarPanelSelection
